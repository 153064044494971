import { sync } from 'vuex-pathify';
export default {
  computed: {
    zoom: sync('map/zoom'),
  },
  methods: {
    getLayerType(layer) {
      if (!layer) return;
      return layer.type === 'features_layer' ? layer.geometry_type : layer.service_type;
    },
    getLayerIcon(layerType) {
      switch (layerType) {
        case 'point':
        case 'multipoint':
          return 'mdi-circle-medium';
        case 'linestring':
        case 'multilinestring':
          return 'mdi-chart-line-variant';
        case 'polygon':
        case 'multipolygon':
          return 'mdi-square-rounded';
        case 'xyz':
          return '$xyz';
        case 'wms':
          return '$wms';
        case 'wmts':
          return '$wmts';
        case 'mvt':
          return '$mvt';
        default:
          return 'mdi-table-large';
      }
    },
    getLayerStylesList(layer) {
      if (!layer.style || (!layer.style.uniques && !layer.style.ranges)) return;
      return (layer.style.uniques ? this.$_objectToArray(layer.style.uniques.values) : layer.style.ranges.values).map(
        value => {
          return {
            ...value,
            key: value.label || value.label === '' ? value.label : value.value,
          };
        }
      );
    },
    getLayerChangeVisibilityTooltip(isVisible, geometryType, hasPermission, isModifySnappingActive) {
      return isModifySnappingActive
        ? this.$i18n.t('button.toChangeLayerVisibility', { reason: this.$i18n.t('button.turnOffEditing') })
        : geometryType === null
          ? this.$i18n.t('button.layerWithoutGeometry')
          : hasPermission
            ? this.$i18n.t('button.layerVisbility', { onOff: this.$i18n.t(`button.${isVisible ? 'off' : 'on'}`) })
            : this.$i18n.t('button.changeLayerVisibilityNoPermission');
    },
    getLayerTooltip(layer) {
      if (layer.has_permission && !this.getIsLayerHiddenByZoom(layer)) {
        return layer.name;
      } else if (!layer.has_permission) {
        return this.$i18n.t('sidebar.noLayerPermissions');
      } else if (this.getIsLayerHiddenByMinzoom(layer)) {
        return this.$i18n.t('sidebar.layerMinZoomVisibility', { suffix: layer.style.minzoom });
      } else if (this.getIsLayerHiddenByMaxzoom(layer)) {
        return this.$i18n.t('sidebar.layerMaxZoomVisibility', { suffix: layer.style.maxzoom });
      } else {
        return layer.name;
      }
    },
    getLayerVisible(layer) {
      return layer.has_permission && layer.visible && layer.geometry_type !== null;
    },
    getIsLayerHiddenByZoom(layer) {
      return this.getIsLayerHiddenByMinzoom(layer) || this.getIsLayerHiddenByMaxzoom(layer);
    },
    getIsLayerHiddenByMinzoom(layer) {
      if (layer.style?.uniques || layer.style?.ranges || !layer.style?.minzoom) {
        return false;
      } else {
        return layer.style?.minzoom > this.zoom;
      }
    },
    getIsLayerHiddenByMaxzoom(layer) {
      if (layer.style?.uniques || layer.style?.ranges || !layer.style?.maxzoom) {
        return false;
      } else {
        return layer.style?.maxzoom < this.zoom;
      }
    },
    getDataSourceIcon(layerType) {
      switch (layerType) {
        case 'point':
        case 'multipoint':
          return '$point';
        case 'linestring':
        case 'multilinestring':
          return 'mdi-vector-line';
        case 'polygon':
          return 'mdi-vector-polygon';
        case 'multipolygon':
          return '$multiPolygon';
        case 'xyz':
          return '$xyz';
        case 'wms':
          return '$wms';
        case 'wmts':
          return '$wmts';
        case 'mvt':
          return '$mvt';
        default:
          return 'mdi-table-large';
      }
    },
    getStyleExternalGraphicIcon(name) {
      return `${import.meta.env.VUE_APP_API_URL}/icons/${name}`;
    },
    getStyleIconColor(style, isGeom = true) {
      if (style && isGeom) {
        return style['fill-color'] || style['circle-color'] || style['line-color'] || this.$_colors.iconIdle;
      } else {
        return this.$_colors.iconIdle;
      }
    },
    getStyleClassOthersValue(styleClass) {
      return styleClass.value || styleClass.value === false || styleClass.value === '' || styleClass.value === 0
        ? styleClass.label || styleClass.label === ''
          ? styleClass.label
          : styleClass.value
        : this.$i18n.t('default.others');
    },
  },
};
